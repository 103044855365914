/* eslint-disable jsx-a11y/alt-text */
import React, {Component, useState} from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import {Col, Container, Row} from "react-bootstrap"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

// import Swiper core and required modules
import SwiperCore, {FreeMode, Navigation, Thumbs} from "swiper"

import "../styles/Gallery.scss"

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs])

function importAll(r) {
  return r.keys().map(r)
}

export default function Gallery() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const images = importAll(
    require.context("../images", false, /\.(png|jpe?g)$/)
  )

  return (
    <Container className="Gallery">
      <div className="GalleryText">
        <div className="verticalText">Gallery</div>
      </div>
      <div className="GallerySwipers">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff"
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{swiper: thumbsSwiper}}
          className="mySwiper2"
        >
          {images.map((img, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img className="myGalleryImg" src={img} />
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={100}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          className="mySwiper1"
        >
          {images.map((img, idx) => {
            return (
              <SwiperSlide key={idx}>
                <img className="myThumbnail" src={img} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </Container>
  )
}
