import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.scss"

import App from "./components/App"
// eslint-disable-next-line no-unused-vars
import firebase from "./utils/firebase"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
