/* eslint-disable jsx-a11y/alt-text */
import React, {Component} from "react"

// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"

import "../styles/Store.scss"

// import Swiper core and required modules
import SwiperCore, {EffectCoverflow, Pagination} from "swiper"
import {Button} from "react-bootstrap"

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination])

export default class Store extends Component {
  render() {
    return (
      <Swiper pagination={true} className="mySwiper">
        <SwiperSlide className="mySwiperSlide">
          <div className="storeDetails">
            <img src={require("../assets/faceless_etsy.png")} />
            <h1>Faceless Portraits</h1>
          </div>
          <div>
            <h2>
              Order a fully customized, hand-drawn, digital portrait to
              give to friends, family, or even yourself! They are perfect
              for commemorating special occasions, or just for fun:). These
              drawings are made to order, and are 100% customizable, so if
              you have any suggestions or requests please feel free to
              message me so we can make your order perfect!
            </h2>
            <Button href="https://www.etsy.com/listing/1075256523">
              Go to Etsy
            </Button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="mySwiperSlide">
          <div className="storeDetails">
            <img src={require("../assets/coloring_Easton.png")} />
            <h1>Custom Line Portraits | Coloring pages</h1>
          </div>
          <div>
            <h2>
              Order a fully customized, hand-drawn, digital line drawing /
              coloring page to give to friends, family, or even yourself!
              They are fun to print and color as many times as you want,
              frame and hang on your wall as is, or both:). These drawings
              are made to order, and are 100% customizable, so if you have
              any suggestions or requests, I am more than happy to make
              sure we make your order perfect!
            </h2>
            <Button href="https://www.etsy.com/listing/1079671597">
              Go to Etsy
            </Button>
          </div>
        </SwiperSlide>
      </Swiper>
    )
  }
}
