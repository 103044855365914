import React, {Component} from "react"
import {Card} from "react-bootstrap"

import "../styles/About.scss"

export default class About extends Component {
  render() {
    return (
      <div>
        <Card>
          <Card.Header>About</Card.Header>
          <Card.Body>
            <Card.Text>
              Hi, i'm Aysia! I am married with a cute son (and a floofy
              dodo for a dog...). I am studying art education at Utah
              Valley University and am hoping to one day become a high
              school art teacher. I have experience with many different
              artistic disciplines including, but not limited to: Print
              Making, Watercolor, Oil Painting, Stone Carving, Digital
              Media, etc.
            </Card.Text>
            <Card.Text>
              Please view the Gallery page for some examples of my work, or
              the Store page for a chance to buy some of my art from my
              Etsy shop. Thanks for visiting, have a great day!
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
