import React, {Component} from "react"

import "../styles/Home.scss"

export default class Home extends Component {
  render() {
    return (
      <div className="mainText">
        <h1 className="title">Aysia Dailey</h1>
        <h1 className="subtitle">Portfolio</h1>
      </div>
    )
  }
}
