import React, {Component} from "react"
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"

import {Container, Navbar, Nav} from "react-bootstrap"
import "../styles/App.scss"

import About from "./About"
import Gallery from "./Gallery"
import Home from "./Home"
import Store from "./Store"

export default class App extends Component {
  render() {
    return (
      <Router>
        <div className="artAysiaApp">
          <Navbar fixed="top" className="sticky-nav">
            <Container>
              <Navbar.Brand className="aysiaNavBrand" href="/">
                Aysia Dailey
              </Navbar.Brand>
              <Nav className="justify-content-end">
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/gallery">Gallery</Nav.Link>
                <Nav.Link href="/store">Store</Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/store" element={<Store />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    )
  }
}
