import {initializeApp} from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAKtr7Cw4R9XIJaiTFW57MYpD5_F9GPd0Y",
  authDomain: "art-aysia.firebaseapp.com",
  projectId: "art-aysia",
  storageBucket: "art-aysia.appspot.com",
  messagingSenderId: "78828996129",
  appId: "1:78828996129:web:012456cd305dee91808560"
}

const app = initializeApp(firebaseConfig)
export default app
